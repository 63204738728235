<template>
	<div v-if="model">
		<template  v-if="ready">
			<div v-if="model.zone">
				<template v-for="z in model.zone">
					<div :key="z.modelzone_id" :class="class_perso">
						<h3 class="mb-3">{{ $t(z.zonetype.modelzonetype_label) }}
							<b-form-checkbox 
								v-if="z.template.modeltemplate_israwable"
								:checked="z.template.modeltemplate_rawmode"
								@change="switchExpertZone(z)"
								name="raw-mode"
								switch
							>
								{{ $t('model.expert_mode') }}
							</b-form-checkbox>
						</h3>
						
						<component
							:is="z.zonetype.modelzonetype_htmltype"
							:placeholder="getTrad('model.placeholder.' + z.zonetype.modelzonetype_code)"
							:required="false"
							:z_id="z.modelzone_id"
							:content="z.content"
							:options="z.options"
							:variable_type="model.type.modeltype_id"
							:answer.sync="responseSelect"
							@change="onValueChange"
							:allow_empty="true"
						/>
					</div>
				</template>
			</div>
			<div v-else>
				<p>{{ $t('model.descriptif_ajout_zone') }}</p>
			</div>
		</template>
		<template v-else>
			<LoadingSpinner class="col-12" />
		</template>
	</div>
	<div v-else>
		<LoadingSpinner class="col-12" />
	</div>
</template>

<script type="text/javascript">

	import TableAction from "@/mixins/TableAction.js"
	import Navigation from "@/mixins/Navigation.js"
	import Accounting from "@/mixins/Accounting.js"
	import Model from "@/mixins/Model.js"
	import File from "@/mixins/File.js"
   	import { EventBus } from "EventBus";

	export default {
		name: "EditZone",
		mixins: [TableAction, Navigation, Accounting, Model, File],
		props: {
			modelable_id: {
				type: [Number, String],
				default: () => 0
			} ,
			modelable_type: {
				type: String,
				default: () => ''
			},
			model_id: {
				type: [Number, String],
				default: () => 0
			},
			class_perso: {
				type: String,
				default: () => 'box'
			},
			lang: {
				type: String,
				default: () => 'fr'
			},
			model: Object
		},
		data () {
			return {
				templates: null,
				ready: false,
				responseSelect: [],
				reponses: [],
				template_zonetype_rawable: ['textareaInput', 'rawTextareaInput']
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.loadModelDetail()
			},
			async loadModelDetail() {
				this.ready = false

				this.templates = await this.loadTemplate(this.modelable_id, this.model_id, this.modelable_type, this.lang)

				for(let index in this.model.zone) { 
					var attr = this.model.zone[index];

					this.model.zone[index]["content"] = ""
				
					let content = this.templates.filter(template => template.modeltemplate_modelzone == attr.modelzone_id)[0]

					if(content != undefined) {
						let contenu = ""
						if(content.modeltemplate_contentwithtable) {
							contenu = content.modeltemplate_contentwithtable
						}
						else {
							contenu = content.modeltemplate_content
						}

						this.model.zone[index].template = content
						this.model.zone[index].options = []

						if(content.templatable_type != null) {
							if(content.templatable_type == "App\\Model\\CheckDepositConfiguration") {
								let all_ribs = await this.getCheckDepositConfigListe(this.modelable_id)
								let tab_options = []
								for (let index = 0; index < all_ribs.length; index++) {
									const element = all_ribs[index];
									const iban = (element.checkdepositconfiguration_iban != null) ? element.checkdepositconfiguration_iban : ''
									const label = element.checkdepositconfiguration_libelle + " " + iban

									tab_options.push({
										id: element.checkdepositconfiguration_id,
										label: label,
									})

									if(content.templatable_id && content.templatable_id == element.checkdepositconfiguration_id){
										contenu = {
											id: element.checkdepositconfiguration_id,
											label: label,
										}
									}
								}

								this.model.zone[index].options = tab_options
							}
						}

						this.model.zone[index].content = contenu
					}
					else {
						this.model.zone[index].template = {}
						this.model.zone[index].content = ""
						this.model.zone[index].options = []
					}

					// Si le type d'input est gérable en mode raw
					const isRawable = this.template_zonetype_rawable.indexOf(
						this.model.zone[index].zonetype.modelzonetype_htmltype
						) > -1

					// On active la possibilité de passer en mode expert, et on backup le type d'input originel
					this.model.zone[index].template.modeltemplate_israwable = isRawable

					if(isRawable && this.model.zone[index].template.modeltemplate_rawmode) {
						this.setZoneExportMode(this.model.zone[index])
					}
				}
				this.ready = true
			},

			onValueChange(obj) {
				obj.val = obj.val ? obj.val : ""
				const zone = this.model.zone.find(z => z.modelzone_id == obj.key)
				if(!zone) return false
				zone.content = obj.val

				this.reponses = this.reponses.filter(reponse => reponse.key != obj.key )
				this.reponses.push(obj)
			},

			async saveTemplate() {

				if(this.reponses.length > 0) {
					for(let index in this.reponses) {
						let template = this.templates.filter(template => template.modeltemplate_modelzone == this.reponses[index].key)[0]

						let content_withtable = ""
						// Gestion des tableaux en attendant TinyMCE les tailles sont en pixels
						// Regex pour regarder s'il y a un tableau avec des colonnes ajustées -> donc qui contient data-colwith="?"
						const regex_style = /<td data-colwidth="[0-9]+"/g
						if(regex_style.test(this.reponses[index].val)) {
							// On garde le contenu avec les data-colwidth pour pouvoir l'enregistrer en bdd et l'afficher correctement lorsque l'on veut modifier le courrier
							content_withtable = this.reponses[index].val
							// Récupération des différents tableaux
							let extracted_tables = this.reponses[index].val.split("<table>")
								.filter(function(v) { 
									return v.indexOf('</table>') > -1
								})
								.map(function(value) { 
									return value.split("</table>")[0]
								})

							extracted_tables.forEach(table => {
								// On rajoute les balises table qui on disparue suite à la récupération
								let content_base = '<table>' + table + '</table>'
								// On ajoute une variable qui correspon au contenu modifié car on a besoin du contenu de base pour pouvoir remplacer
								let content_modified = content_base

								const regex_table = /<table>/g
								const regex_tr = /<tr>/g
								const regex_td = /<td>/g
								// On compte le nombre de table dans le contenu
								const nb_table = regex_table.test(content_modified) ? content_modified.match(regex_table).length : 0
								// S'il y a des tableaux dans des tableaux on ne fait rien pour éviter de casser les x tableaux
								if(nb_table == 1 && regex_style.test(content_modified)) {
									// On compte le nombre de td dans le contenu
									const nb_td = regex_td.test(content_modified) ? content_modified.match(regex_td).length : 0
									// Cas où il reste des td sans style (donc qui n'ont pas de data-colwidth)
									if(nb_td != 0) {
										// Ajout d'un style au table pour qu'il prenne 100% de la taille de la page (utile pour gérer la taille des tr/td en dessous)
										content_modified = content_modified.replace(regex_table, '<table style="width:100%">')
									}
									// On compte le nombre de ligne dans le tableau
									const nb_tr = regex_tr.test(content_modified) ? content_modified.match(regex_tr).length : 0
									// Ajout d'un style au tr pour qu'il prenne 100% de la taille du tableau (utile pour gérer la taille des td en dessous)
									content_modified = content_modified.replace(regex_tr, '<tr style="width:100%">')

									// On compte combien il y a d'occurence de data-colwidth dans le contenu
									const matches = content_modified.match(regex_style)
									let all_width = 0

									matches.forEach(match => {
										// Ajout la taille de chaque data-colwith
										all_width += parseInt(match.split('"')[1])
									})
									// Division la taille de tous les data-colwidth par le nombre de ligne du tableau pour avoir la taille du tableau
									let table_width = all_width / nb_tr
									// Cas où tous les td on un data-colwidth
									if(nb_td == 0 && this.reponses[index].width) {
										// Dans ce cas là, la taille du tableau n'est pas 100% mais le % l'addition des data-colwitdh par rapport à la taille de l'encart tiptap
										let table_poucentage = Math.round((table_width * 100 / this.reponses[index].width) * 100) / 100
										content_modified = content_modified.replace(regex_table, '<table style="width:' + table_poucentage + '%">')
									}

									let pourcentage = 0
									if(this.reponses[index].width) {
										// Comparaison de la taille de la page et de la taille du tableau pour savoir sur quelle taille on doit se baser comme taille maximale
										if(this.reponses[index].width > table_width) {
											let new_style = []
											matches.forEach(match => {
												const split = match.split("\"")
												// Calcul du pourcentage qui va être mis dans le "style=width:??%" (% arrondi à 2 décimales)
												const width = Math.round(((((parseInt(split[1]) * this.reponses[index].width) / this.reponses[index].width) * 100) / this.reponses[index].width) * 100) / 100
												// Ajout du pourcentage au pourcentage total pour calculer le pourcentage restant lorsqu'il y en a besoin
												pourcentage += width
												// Tableau avec tous les nouveaux styles
												new_style.push('style="width:' + width + '%"')
											})

											// Nouveau regex pour qu'il ne retourne que le 1er data-colwidth
											const regex_first_style = /data-colwidth="[0-9]+"/
											// Remplacement des data-colwidth= par les style=
											new_style.forEach(style => {
												content_modified = content_modified.replace(regex_first_style, style)
											})

											// On compare le nombre de td total par rapport au nombre de td avec du style, si c'est différent on doit ajouter un style aux td sans style
											if(new_style.length != nb_td && nb_td != 0) {
												// Ajout des styles sur les td restants
												content_modified = content_modified.replace(regex_td, '<td style="width:'+ Math.round(((100 - (pourcentage / nb_tr))) * 100) / 100 +'%"')
											}
										}
									}
									// On remplace l'ancien tableau par le nouveau tableau stylé
									this.reponses[index].val = this.reponses[index].val.replace(content_base, content_modified)
								}
							})
						}

						let params = {}
						if(content_withtable != "") {
							params = {
								modeltemplate_content: this.reponses[index].val,
								modeltemplate_rawmode: template.modeltemplate_rawmode,
								modeltemplate_contentwithtable: content_withtable
							}
						}
						else {
							params = {
								modeltemplate_content: this.reponses[index].val,
								modeltemplate_rawmode: template.modeltemplate_rawmode
							}
						}

						if(this.reponses[index].type == 'FileInput') {
							try {
								let file = this.reponses[index].val
								let media = {}
								if(file){
									media = await this.uploadFile(file, template.modeltemplate_id, 'App\\Model\\ModelTemplate')
								}
								let new_link = media.code_retour == 0 ? media.retour.url : ''
								//Je mets à jour mon template
								params = {
									modeltemplate_content: new_link
								}

								//Je vais chercher la zone pour la mettre à jour
								for(let i in this.model.zone) { 
									var attr = this.model.zone[i];
									
									if(this.reponses[index].key == attr.modelzone_id) {
										this.model.zone[i].content = params.modeltemplate_content
										this.$set(this.model.zone, i, this.model.zone[i])
									}
								}
								
							} catch (err) {
								this.failureToast()
							}
						}
						else if(this.reponses[index].type == "SelectInput") {
							params = {
								modeltemplate_content: "",
								templatable_id: this.reponses[index].val.id
							}
						}

						await this.updateModelTemplate(this.modelable_id, this.model_id, template.modeltemplate_id, params, this.modelable_type)
					}
				}
				EventBus.$emit("EditModel::saved", true)
			},

			switchExpertZone(zone) {
				zone.template.modeltemplate_rawmode = !zone.template.modeltemplate_rawmode

				if(zone.template.modeltemplate_rawmode === true) {
					this.setZoneExportMode(zone)
					return
				}
				this.unsetZoneExpertMode(zone)
			},

			setZoneExportMode(zone) {
				if(!zone.zonetype.modelzonetype_orig_htmltype) {
					zone.zonetype.modelzonetype_orig_htmltype = zone.zonetype.modelzonetype_htmltype
				}
				zone.zonetype.modelzonetype_htmltype = 'rawTextareaInput'
			},

			unsetZoneExpertMode(zone) {
				zone.zonetype.modelzonetype_htmltype = zone.zonetype.modelzonetype_orig_htmltype
			}
		},
		watch: {
			responseSelect(val){
				this.onValueChange(val)
			}
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			fileInput : () => import('@/components/Model/InputsType/FileInput'),
			textInput : () => import('@/components/Model/InputsType/TextInput'),
			textareaInput : () => import('@/components/Model/InputsType/LongtextInput'),
			rawTextareaInput : () => import('@/components/Model/InputsType/RawLongtextInput'),
			selectInput : () => import('@/components/Model/InputsType/SelectInput'),
		}
	}
</script>
